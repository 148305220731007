import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import {
  Title,
  Button,
  Section,
  Box,
  Text,
} from "../../components/Core";
import imgYellow from "../../assets/image/banner/hero-shape-yellow.png";
import bannerImage from '../../assets/image/banner/homepage_banner.jpg';


const SectionStyled = styled(Section)``;

const ShapeYellow = styled(Box)`
  position: absolute;
  left: 0;
  transform: translateX(-63%) translateY(50%);
  bottom: 0;
  z-index: 1;
`;

const sectionStyle = {
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "inherit",
  backgroundImage: `url(${bannerImage})`,
  paddingTop: '350px'
};


const Hero = () => {
  return (
    <>
      <SectionStyled style={sectionStyle}>
        <div className="pt-5"></div>
        <ShapeYellow className="d-none d-md-block">
          <img src={imgYellow} alt="" className="img-fluid" />
        </ShapeYellow>
        <Container
          className="position-relative"
          css={`
            z-index: 10;
          `}
        >
          <Row>

            <Col
              lg="12"
              xl="12"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <Box py={[null, null, null, 5]} pr={50} pt={[4, null]}>
                <Title variant="hero" color="#ffffff" className="d-none d-lg-block d-xs-block">
                  <span className="d-none d-lg-block d-xs-block" >Ordering experience that brings your customers back!</span>
                </Title>
                <h4 className="d-block d-sm-none" style={{ fontSize: '35px', color: '#fff', lineHeight: '56px', marginBottom: '30px' }}>Ordering experience<br /> that brings your<br /> customers back!</h4>
                <Text mb={4} color="#ffffff">
                  Accept takeout and delivery orders directly from your website and social media pages.
                </Text>
                <a href="https://calendly.com/plento/30min" rel="noreferrer" target="_blank" alt="Book Demo" style={{ marginRight: '20px' }}>
                  <Button size="small" borderRadius="10px" width="5%" style={{ padding: '5px', marginBottom: '20px' }}>
                    Book Demo
                  </Button>
                </a>
                <a href="https://login.plento.io/register" rel="noreferrer" target="_blank" alt="Sign Up">
                  <Button size="small" borderRadius="10px" width="5%" style={{ padding: '5px' }}>
                    Sign Up
                  </Button>
                </a>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
