import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";

const SectionStyled = styled(Section)``;
const FaqCard = ({ title, children, ...rest }) => (
  <Box {...rest}>
    <Title variant="card" mb={4} fontSize="24px" fontWeight="900" color="light">
      {title}
    </Title>
    <Text variant="small" color="lightShade">
      {children}
    </Text>
  </Box>
);

const FAQ = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center">
          <Col xl="7" lg="8" md="9">
            <div className="text-center">
              <Title color="light">
                Frequently
                <br className="d-none d-sm-block" /> Asked Questions
              </Title>
              <Text color="lightShade">
                We give you a straight forward answer!
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col lg="6" className="mt-5">
            <FaqCard title="Does Plento offer delivery service?">
              No. However, Plento provides a complete delivery management tools to manage your dispatch and drivers.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Can we offer coupons to our customers?">
              Yes. You can create coupons and allow your customers to apply at checkout or make the coupon apply automatically at checkout.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="How does payment processing work?">
            We work with Clover to process all our online orders. If you would like to create a Clover account please check <a className="ml-1 white" rel="noreferrer" href="https://clover.com" style={{ color: '#fff!important' }} target="_blank" >www.clover.com</a>
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Does Plento help with menu setup and training?">
              Ofcourse! Your restaurant will be assigned a dedicated account manager once you sign up with Plento. Your account manager will help you setup your menu, provide training on how to manage your menu and help with any ongoing support.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="How does my restaurant receive the online orders?">
              Our restaurant manager app is available for Android, iOS (iPad) and Windows. Plento will also send order alerts along with the order details by email and SMS.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Does Plento connect to my kitchen printer?">
              No. Plento restaurant manager app can be connected with supported Epson and Star Micronics printers through Bluetooth or Ethernet.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="How do we add the ordering link to our website?">
              You will be emailed your restaurant’s ordering link and instructions to add the ordering widget or link to your existing website and Facebook page. If you are looking for a new website then we got you covered! Plento restaurant partners get a fully customizable SEO optimized website as a part of their subscription. Secured hosting and SSL certificate are included as well.
            </FaqCard>
          </Col>
          <Col lg="6" className="mt-5">
            <FaqCard title="Can we connect our existing website domain with the Plento website?">
              Yes! You can connect your existing website domain with Plento.
            </FaqCard>
          </Col>

          <Col lg="12" className="" hidden>
            <Box className="text-center" pt={5}>
              <Text variant="small" color="lightShade">
                Didn’t find your answer?{" "}
                <a
                  href="/"
                  target="_blank"
                  css={`
                    color: ${({ theme }) => theme.colors.primary} !important;
                  `}
                >
                  Contact us here
                </a>
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default FAQ;
