import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import imgContent from "../../assets/image/full-control/content1-img.png";

const SectionStyled = styled(Section)`
  border-top: 0px solid #242427;
`;

const FullControl = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="6" className="my-5 my-lg-0">
            <div className="">
              <Box>
                <img src={imgContent} alt="" className="img-fluid" />
              </Box>
            </div>
          </Col>
          <Col
            md="10"
            lg="6"
            className="mt-3 mt-lg-0"
          >
            <div className="mt-4">
              <Title color="light">
                Full Control
              </Title>
              <Text color="lightShade">
                We understand the restaurant business from the core and that’s why we created Plento to help restaurants to take full control over their online ordering and loyal customers.
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>

  </>
);

export default FullControl;
