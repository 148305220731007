import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";


import imgContent from "../../assets/image/byod/content1-img.png";
import styles from './style.module.scss'

const SectionStyled = styled(Section)`
  border-top: 0px solid #242427;
`;

const Content1 = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col
            md="10"
            lg="6"
            className={`${styles.column1} mt-3 mt-lg-0`}
          >
            <div className="mt-4">
              <Title color="light">
                BYOD
              </Title>
              <Text color="lightShade">
                Plento sends all the orders on time to your Android tablet or an iPad or to your existing Windows computer that runs your restaurant software. Print order tickets on supported Epson or Star Micronics printers.
              </Text>
            </div>
          </Col>
          <Col lg="6"
            className={`${styles.column2} my-5 my-lg-0`}>
            <div className="">
              <Box>
                <img src={imgContent} alt="" className="img-fluid" />
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Content1;
