import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import imgContent1 from "../../assets/image/why-plento/easy-image-1.jpg";
import imgContent2 from "../../assets/image/why-plento/easy-image-2.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.dark}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content2 = () => (
  <>
    <Section bg="#1e1e20;">
      <Container>
        <Row className="justify-content-center">
          <Col xl="12" lg="12" md="12">
            <div className="text-center">
              <h2 style={{ fontSize: '40px', color: '#ffffff' }}>Why Plento and Why now?</h2>
              <Text color="lightShade">
                Offering a great online ordering experience doesn’t have to be that complicated or expensive! Plento offers flexible pricing with full control over customers without compromising their ordering experience. Our managed delivery feature allows the restaurants to hire and manage their own drivers with a fully integrated dispatch management. Plento enables the restaurants to keep their online ordering and food delivery service more personalized and reliable.
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4" sm="5">
            <ContentImg>
              <img src={imgContent1} alt="" className="img-fluid d-none d-sm-block" />
            </ContentImg>
          </Col>
          <Col lg="8" sm="7" className="mb-4">
            <ContentImg>
              <img src={imgContent2} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
        </Row>
      </Container>
    </Section>
    <Section bg="#f86d64;" className="d-none">
      <Container>
        <Row className="justify-content-center">
          <Col xl="12" lg="12" md="12">
            <div className="text-center">
              <Title color="light">Solution</Title>
              <Text color="lightShade">
                Plento offers flexible pricing options based on sales volume and order requested time. Our
                managed delivery feature allows the restaurants to hire and manage their drivers through our network.
                This allows the restaurants to keep the delivery service more personalized and reliable.
              </Text>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4" sm="5" className="mb-4 ">
            <ContentImg>
              <img src={imgContent1} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
          <Col lg="8" sm="7" className="mb-4">
            <ContentImg>
              <img src={imgContent2} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
