import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import imgContent from "../../assets/image/managed-delivery/content1-img.png";

const SectionStyled = styled(Section)`
  border-top: 0px solid #242427;
`;

const ManagedDelivery = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="6" className="my-5 my-lg-0">
            <div className="">
              <Box>
                <img src={imgContent} alt="" className="img-fluid" />
              </Box>
            </div>
          </Col>
          <Col
            md="10"
            lg="6"
            className="mt-3 mt-lg-0"
          >
            <div className="mt-4">
              <Title color="light">
                Managed Delivery
              </Title>
              <Text color="lightShade">
                Delivery zones, dispatch and driver management features help your restaurant to manage your restaurants own food delivery service like never before. Setup delivery order minimum and delivery fees based on delivery zones.
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>

  </>
);

export default ManagedDelivery;
