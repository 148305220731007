import React from "react";
import Hero from "../sections/landing3/Hero";
// import VideoCTA from "../sections/landing3/VideoCTA";
// import Feature from "../sections/landing3/Feature";
import FullControl from "../sections/landing3/FullControl";
import FlexiblePricing from "../sections/landing3/FlexiblePricing";
import SeamlessExperience from "../sections/landing3/SeamlessExperience";
import Byod from "../sections/landing3/Byod";
import ManageDelivery from "../sections/landing3/ManagedDelivery";
import Content2 from "../sections/landing3/Content2";
import FAQ from "../sections/landing3/FAQ";
import CTA from "../sections/landing3/CTA";

import PageWrapper from "../components/PageWrapper";

const LandingPage3 = () => {
  return (
    <>
      <PageWrapper headerDark footerDark>
        <Hero />
        {/* <VideoCTA /> */}
        {/* <Feature /> */}
        <Content2 />
        <FullControl />
        <FlexiblePricing />
        <SeamlessExperience />
        <Byod />
        <ManageDelivery />
        <FAQ />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default LandingPage3;
