import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import imgContent from "../../assets/image/flexible-pricing/content1-img.png";
import styles from './style.module.scss'

const SectionStyled = styled(Section)`
  border-top: 0px solid #242427;
`;

const FlexiblePricing = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col
            md="10"
            lg="6" xs="12" sm="12"
            className={`${styles.column1} mt-3 mt-lg-0`}
          >
            <div className="mt-4">
              <Title color="light">
                Flexible Pricing
              </Title>
              <Text color="lightShade">
                Plento offers both monthly and pay as you go options. Choose between our Standard and Premium plan based on what suits your restaurant. Your business is never tied to a contract or an annual commitment. No contract, No setup fee and No commissions!
              </Text>
            </div>

          </Col>
          <Col lg="6" xs="12" sm="12" className={`${styles.column2} my-5 my-lg-0`}>
            <div className="">
              <Box>
                <img src={imgContent} alt="" className="img-fluid" />
              </Box>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default FlexiblePricing;
