import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Section, Box, Text } from "../../components/Core";
import imgContent from "../../assets/image/seamless-experience/content1-img.png";

const SectionStyled = styled(Section)`
  border-top: 0px solid #242427;
`;

const SeamlessExperience = () => (
  <>
    <SectionStyled bg="dark">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="6" className="my-5 my-lg-0">
            <div className="">
              <Box>
                <img src={imgContent} alt="" className="img-fluid" />
              </Box>
            </div>
          </Col>
          <Col
            md="10"
            lg="6"
            className="mt-3 mt-lg-0"
          >
            <div className="mt-4">
              <Title color="light">
                Seamless Experience
              </Title>
              <Text color="lightShade">
                Amazing user experience that creates a smile on your customers face every time they order. Your customers can pay securely through Apple Pay or Android Pay right from your website or your Facebook page.
              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default SeamlessExperience;
